<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.phone"
        placeholder="请输入用户电话"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <el-button class="filter-item" type="primary" @click="addde"
        >新增</el-button
      >
      <el-button class="filter-item" type="danger" @click="allDel"
        >一键清空</el-button
      >
    </div>
    <div style="margin-bottom: 5px">用户数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="ID" width="95">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="头像" min-width="60" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.avatar" class="user-avatar" />
        </template>
      </el-table-column> -->

      <el-table-column label="联系电话" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.account }}
        </template>
      </el-table-column>
      <el-table-column label="优先购数量" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.first_buy_num }}
        </template>
      </el-table-column>

      <el-table-column
        label="操作"
        align="center"
        width="230"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button type="warning" size="mini" @click="handleForbid(scope.row)"
            >编辑</el-button
          >
          <el-button type="danger" size="mini" @click="remove(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span style="display: block">请输入白名单用户手机号</span>
      <el-input
        v-model="whiteinput"
        placeholder="多个手机号以换行隔开"
        type="textarea"
        :autosize="{ minRows: 5, maxRows: 999 }"
        style="width: 300px"
      ></el-input>

      <span style="display: block; margin: 30px 0 0"
        >请输入白名单用户优先购购数量</span
      >
      <el-input
        v-model="whitequantity"
        placeholder="请输入有限购数量"
        style="width: 300px"
        type="number"
      ></el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        id: "",
        email: "",
        nickname: "",
        is_normal: "",
        type: 1,
      },
      dateArr: null,
      dialogVisible: false,
      whiteinput: "",
      whitequantity: 1,
    };
  },
  created() {
    this.getList();
  },
  watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_at = newVal[0];
        this.listQuery.end_at = newVal[1];
      } else {
        this.listQuery.start_at = "";
        this.listQuery.end_at = "";
      }
    },
  },
  methods: {
    determine() {
      let arr = this.whiteinput.split("\n");
      arr = arr.filter((e) => e != "");
      request({
        url: "/api/backend/firstBuyWhiteList/manyAdd",
        method: "post",
        data: {
          phones: arr,
          first_buy_num: this.whitequantity,
        },
      }).then(() => {
        this.getList();
        this.whiteinput = "";
        this.dialogVisible = false;
        this.$message({
          type: "success",
          message: "修改成功",
        });
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/firstBuyWhiteList/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    addde() {
      this.dialogVisible = true;
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleForbid(val) {
      this.$prompt("修改优先购数量", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        //   inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: "Incorrect format",
        inputType: "Number",
        inputValue: val.first_buy_num,
      })
        .then(({ value }) => {
          if (value < 1) {
            this.$message({
              type: "error",
              message: "优先购数量必须大一等于1",
            });
            return;
          }
          request({
            url: "/api/backend/firstBuyWhiteList/store",
            method: "post",
            data: {
              id: val.id,
              first_buy_num: value,
            },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "修改成功",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "修改失败",
          });
        });
    },
    remove(val) {
      this.$confirm("此操作将删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/firstBuyWhiteList/del",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    //上下架
    allDel() {
      this.$confirm("此操作将一键清空白名单, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/firstBuyWhiteList/allDel",
            method: "post",
            data: {},
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    changeFirstBuy(val, text) {
      this.$confirm("此操作将" + text + "该用户优先购权限, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/users/changeFirstBuy",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
